import React from 'react'
import { connect } from 'react-redux'
import { graphql } from 'gatsby'
import { get } from 'lodash'

import ARYImage from '../components/ary-image'
import {
  SectionContact,
  SectionImageScroll,
  SectionLanding,
  SectionSplitIllustration,
  SectionStoryCarousel,
  SectionText,
} from '../components/section'
import PageLayout from '../components/page-layout'
import PageScroll from '../components/page-scroll'
import { getSEOFromPage } from '../components/seo'

import breakpoints from '../lib/constants/breakpoints.json'
import { formatWpImageForARYImage } from '../lib/_helpers'
import { WPImageFragment } from '../lib/fragments'

import iHouses from '../assets/imgs/home/houses.jpg'
import iHousesMobile from '../assets/imgs/home/houses_mobile.jpg'
import iIllus1 from '../assets/imgs/home/illus_1.png'
import iIllus2 from '../assets/imgs/home/illus_2.png'

class Homepage extends React.Component {
  render() {
    const { data, screenSize, location } = this.props

    let stories = get(data, 'stories.edges')
    if (stories && stories.length > 6) {
      stories = stories.slice(0, 6)
    }

    return (
      <PageLayout location={location} seo={getSEOFromPage(get(data, 'page'))}>
        <PageScroll
          breadCrumbDark={[false, false, true, false, true]}
          showFooter={true}
        >
          <SectionLanding
            title="Build better. Live better."
            body={`
						<p>
							We believe there’s a better way to create homes.<br />
							That everyone deserves brilliant housing.<br />
							That people and quality come first.
						</p>
						`}
            showIndicator={true}
          />
          <SectionImageScroll
            title="Houses."
            body={`<p>Choose your Kiss House.</p>`}
            image={{
              src: iHouses,
              width: 2560,
              height: 1600,
            }}
            mobileImage={{
              src: iHousesMobile,
              width: 1200,
              height: 1600,
            }}
            link="/houses/"
          />
          <SectionSplitIllustration
            splits={[
              {
                title: 'About us.',
                body: `<p>Meet our team and see their work. </p>`,
                colour: 'purpleLight',
                link: '/about-us/',
                ctas: [
                  {
                    label: 'Meet the team',
                    link: '/about-us/#team',
                  },
                  {
                    label: 'Past projects',
                    link: '/about-us/#past-projects',
                  },
                ],
                image: {
                  src: iIllus1,
                  width: 2400,
                  height: 1332,
                },
                imageAlignment: 'bottom',
              },
              {
                title: 'Info.',
                body: `<p>Discover more.</p>`,
                colour: 'green',
                link: '/info/',
                ctas: [
                  {
                    label: 'Customer journey',
                    link: '/info/#journey',
                  },
                  {
                    label: 'FAQs',
                    link: '/info/#faqs',
                  },
                  {
                    label: 'The details',
                    link: '/info/#the-details',
                  },
                ],
                image: {
                  src: iIllus2,
                  width: 2400,
                  height: 1332,
                },
                imageAlignment: 'bottom',
              },
            ]}
          />
          <SectionStoryCarousel
            title="Stories."
            body={`<p>Stories about us and things that inspire and spark our imagination.</p>`}
            stories={
              stories && stories.length > 0
                ? stories.map((_story, _i) => {
                    return {
                      image: formatWpImageForARYImage(
                        _story.node.acf.thumbnail
                      ),
                      title: _story.node.title,
                    }
                  })
                : null
            }
            link="/stories/"
          />
          <SectionContact />
        </PageScroll>
      </PageLayout>
    )
  }
}

const mapStateToProps = (state) => {
  const { ui } = state

  return {
    screenSize: ui.screenSize,
  }
}

export default connect(mapStateToProps)(Homepage)

export const query = graphql`
  query($id: String!) {
    page: wordpressPage(id: { eq: $id }) {
      seo_meta {
        title
        description
      }
    }
    stories: allWordpressPost(
      sort: { fields: [date], order: DESC }
      limit: 6
      filter: {
        acf: {
          hide_from_stories: { eq: false }
          thumbnail: { source_url: { ne: null } }
        }
      }
    ) {
      edges {
        node {
          title
          id
          wordpress_id
          acf {
            thumbnail {
              ...ARYImageWPMedia
            }
          }
        }
      }
    }
  }
`
